import React, { useEffect, useState } from "react"
import PageWrapper from "../components/PageWrapper"
import "../css/index.css";

const goopIntro = require('../assets/intro_goop.gif');

function HomePage() {

  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      // setShowIntro(false);
    }, 1500);
  });

  return (
    <PageWrapper>
      {showIntro && (
        <React.Fragment>
          <img class="goop_gif" src={goopIntro} />
          {/* <h1 class="loading_text">Coming Soon...</h1> */}
        </React.Fragment>
      )}
    </PageWrapper>
  )
}

export default HomePage
